.greetings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Prima Sans Mono Std Roman', monospace;
    margin: 30vh 0; /* Updated margin for both top and bottom */
  }
  
  h1 {
    font-size: 2.5em;
  }
  
  p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .terminal-header {
    font-family: 'Prima Sans Mono Std Roman', monospace;
    color: #4CAF50; /* Green color, you can change it to your preference */
    margin-bottom: 10px; /* Adjust the margin as needed */
  }

  .resume-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .close-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #2980b9;
  }